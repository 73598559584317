import React from 'react'
import {Link} from 'gatsby'
import Layout from '../layouts/layout'

const NotFoundPage = () => {
  return (
    <Layout title={`Upsi! Gibt's nicht!`}>
      <title>Not found</title>
      <h1>Page not found</h1>
      <p>
        Sorry man{' '}
        <span role='img' aria-label='angry cat'>
          😾
        </span>{' '}
        Die Seite gibt's nicht
        <br />
        <br />
        <Link to='/'>Geh bitte weg!</Link>
      </p>
    </Layout>
  )
}

export default NotFoundPage
